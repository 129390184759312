import { Injectable } from '@angular/core';
import {Card, CardInterface, PanelInterface} from '../entity/entity.card';
import Dexie from 'dexie';
import {HomeComponent} from '../../kaban/page/home/home.component';

@Injectable({
  providedIn: 'root'
})
export class StateServiceService {

  protected db: any;
  protected panel: PanelInterface = {} as PanelInterface;
  protected card: Card = new Card(0, 0, '', '', 0, 0, 0, false, 0);
  protected rows: Card[] = [];
  protected NumberOfRows: number;
  protected DatabaseExist: boolean;
  protected  TABLE_NAME_ARRAY_LOCAL_STORAGE = 'STORAGE_ARRAY';
  columnCentral: CardInterface[] = [];
  columnOneData: CardInterface[] = [];
  columnTwoData: CardInterface[] = [];

  columnTreeData: CardInterface[] = [];

  constructor() {
    this.db = new Dexie('StartupDatabase');
    this.db.version(1).stores({
      card: '++id&,column,title,text,day,month,year,locked,table',
      panel: '++id&,panel,name,actual',
    });
    this.NumberOfRows = 0;
    this.DatabaseExist = true;
  }


  /*
      connect to the database and return a bool
   */
  connectToDatabase(): void  {
    this.db.open().catch((error: Error) => {
      alert('Error during connecting to database ' + error);
    });

  }

  /*
    Card service for update/create
  */
  QueryCard(id: number,
            column: number,
            title: string,
            text: string,
            day: number,
            month: number,
            year: number,
            locked: boolean,
            panel: number,
  ): CardInterface {
    return {
      id,
      column,
      title,
      text,
      locked,
      panel,
      date: {
        day,
        month,
        year,

      }
    };
  }
  /*
     verify if the database exist
  */
  async isExist(): Promise<boolean> {
    return await Dexie.exists('StartupDatabase').then((exist: boolean) => exist);
  }
  /*
    watch the number of line in the database
 */
  async  rowsNumber(): Promise<number> {
    return this.db.card.toCollection().count((counts: number) => counts);
  }

  async clearRows(): Promise<void> {
    this.db.card.clear().then((result: Error)  => console.log(result));
  }



// Card methods
// ----------------------------------------------------------------------------------------------------

  /*
      create card at button push
   */
  addRowCard(cards: CardInterface): void {
    this.db.card.add(cards).catch((error: Error) => error);
    this.card = new Card(0, 0, '', '', 0, 0, 0, false, 0);
  }


  async  GetAllCard(): Promise<CardInterface[]>{
    return this.db.card.toArray().then((rows: CardInterface[]) => rows );
  }


  // used in card component
  UpdateCard(id: number, card: CardInterface): void {
    this.db.card.update(id, card).then((r: Error) => r);
  }

  /*
    remove a card from the indexeddb
 */
  // used
  async RemoveCard(id: number): Promise<void> {
    await this.db.card.where('id').equals(id).delete();
  }


// Panel methods
// ----------------------------------------------------------------------------------------------------
  /*
      create startup panel at startup
   */
  // used
  async CreateBasicPanel(): Promise<void> {
    const panelFirstExist = await this.GetAllPanel().then(response => response);
    if (panelFirstExist.length < 1) {
      const tableObject: PanelInterface = {
        id: 0,
        panel: 0,
        name: '',
        actual: true
      };
      this.db.panel.add(tableObject).catch((error: Error) => console.log(error));
    }
  }



  /*
      create a table at button push
   */

  async addPanel(panel: PanelInterface): Promise<void> {
    await this.db.panel.add(panel).catch((error: Error) => error);
  }

  async deletePanel(id: number): Promise<void> {
    await this.db.panel.where('id').equals(id).delete();
    await this.db.card.where('table').equals(id).delete();
  }

  async GetAllPanel(): Promise<PanelInterface[]>{
    return this.db.panel.toArray().then((rows: PanelInterface[]) => rows);
  }

  // used
  updatePanel(id: number, panel: PanelInterface): void {
    this.db.panel.update(id, panel).then((r: Error) => r);
  }

  async changePanel(item: PanelInterface): Promise<void> {
    await this.db.panel.toCollection().modify((panel: PanelInterface) => {
      panel.actual = false;
    });
    await this.db.panel.update(item.id, item);

  }



}
