import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetActualObservableService {

  private actualSource = new BehaviorSubject(0);
  currentActual = this.actualSource.asObservable();

  constructor() { }

  changeActual(index: number): void  {
    this.actualSource.next(index);
  }
}



