<div id="options">
  <div id="main">
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>expand_more</mat-icon></button>
    <mat-menu  #menu='matMenu' >
      <div *ngFor="let item of PanelArray;let i = index">
        <button mat-menu-item  (click)="ChangePanel(item)">
          {{item.panel}} {{item.name}}
        </button>
      </div>
    </mat-menu>
    <div id="numberTable">
      <div class="table color-grey font-inter font-weigh-bold size-xl" (click)="ChangePanel(item)" *ngFor="let item of filterPanel">{{item.panel}}</div>
    </div>
    <input type="text" class="font-inter color-grey size-xl" [(ngModel)]="tableNameInput" (focusout)="SaveTable()" [placeholder]="tableName">
    <mat-icon (click)="AddPanel()">add</mat-icon>
    <mat-icon (click)="DeletePanel()">remove</mat-icon>
  </div>

</div>
