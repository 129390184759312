import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  @Input()
  column: string;

  constructor() {
    this.column = '';
  }



  setColor(): string {

    if (this.column === 'columnOne') {
      return 'orange';
    }

    if (this.column === 'columnTwo'){
      return 'red';
    }

    if (this.column === 'columnTree'){
      return  'green';
    }
    return '';

  }
  ngOnInit(): void {
  }

}
