import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PanelInterface} from '../../../state/entity/entity.card';
import {StateServiceService} from '../../../state/service/state-service.service';
import {SetActualObservableService} from '../../../state/service/set-actual-observable.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, OnDestroy {

  @Input()
  tableNameInput: string;

  tableName: string;

  actual: number;

  highestPanel: number;

  highestId: number;

  @Input()
  PanelArray: PanelInterface[];

  filterPanel: PanelInterface[];

  subscription: Subscription = new Subscription();

  panelObject: PanelInterface = {panel : 0, name : '', actual: false, id: 0};

  constructor(private stateService: StateServiceService,
              private actualObservable: SetActualObservableService) {
    this.tableNameInput = '';
    this.PanelArray = [this.panelObject];
    this.filterPanel = this.PanelArray;
    this.tableName = '';
    this.actual = 0;
    this.highestPanel = 0;
    this.highestId = 0;
  }

  /*
      save a table in the array
   */
  async SaveTable(): Promise<void>{
    if (this.tableNameInput.length > 0){
      this.tableName = this.tableNameInput;
      const object = this.PanelArray.filter(item => item.panel === this.actual)[0];
      object.name = this.tableNameInput;
      this.PanelArray = this.PanelArray.filter(item => item.panel !== this.actual);
      this.PanelArray.push(object);
      this.stateService.updatePanel(this.actual, object);
    }else{
      this.tableName = '';
    }
  }

  /*
   * get All
   */
  async getAllPanel(): Promise<void> {
    await this.stateService.GetAllPanel().then(response => this.PanelArray = response);
    this.onChange();
  }
  /*
      filter the large number of table
   */

  /*
      emitter for add a table
   */
  async AddPanel(): Promise<void>{
    this.highestPanel++;
    this.highestId++;
    const newPanel: PanelInterface = {
      panel: this.highestPanel,
      id: this.highestId,
      actual: false,
      name: '',
    };
    this.PanelArray.push(newPanel);
    await this.stateService.addPanel(newPanel).then(r => r);
    this.onChange();
  }


  async DeletePanel(): Promise<void> {
    if (typeof this.actual !== 'undefined') {
      await this.stateService.deletePanel(this.actual).then(r => r);
      this.PanelArray = this.PanelArray.filter(item => item.id !== this.actual);
      if ( this.PanelArray.length < 1){
        const originalPanel: PanelInterface = {
          panel: this.highestPanel,
          name: 'données détruite',
          actual: true,
          id: this.highestId,
        };
        this.PanelArray.push(originalPanel);
        await this.stateService.addPanel(originalPanel).then(r => r);
      }
      this.onChange();
    }
  }

  /*
      change the table to another
   */
  async ChangePanel(panel: PanelInterface): Promise<void> {
    const panelUpdated: PanelInterface = {
      panel: panel.panel,
      actual: true,
      id: panel.id,
      name: panel.name
    };
    await this.stateService.changePanel(panelUpdated).then(r => r);
    const panelItem = this.PanelArray.filter(item => item.id === panel.id)[0];
    panelItem.actual = true;
    this.PanelArray = this.PanelArray.filter(item => item.id !== panel.id);
    this.PanelArray.map((item) => item.actual = false);
    this.PanelArray.push(panelItem);
    this.actual = panel.id;
    this.actualObservable.changeActual(this.actual);
    this.onChange();
  }
  // lib
  // ----------------------------------------------------------------

  filterTableName(): void{
    this.filterPanel = this.PanelArray.filter((item: PanelInterface, index: number) => index < 3);
  }

  findActual(): void {
    const actual  = this.PanelArray.filter(item => item.actual)[0];
    this.actual = actual.panel;
    if (actual.name.length > 0){
      this.tableNameInput = actual.name;
    }else{
      this.tableNameInput = '';
    }

  }

  findHighestPanel(): void {
    let  highest = 0;
    for (const item of this.PanelArray) {
      highest = item.panel;
    }
    this.highestPanel = highest;
  }

  findHighestId(): void {
    let  highest = 0;
    for (const item of this.PanelArray) {
      highest = item.id;
    }
    this.highestId = highest;
  }

  onChange(): void {
    this.filterTableName();
    this.findActual();
    this.findHighestPanel();
    this.findHighestId();
  }



  ngOnInit(): void {
    this.getAllPanel().then(r => r);
    this.subscription = this.actualObservable.currentActual.subscribe(index => this.actual = index);
  }

  ngOnDestroy(): void  {
    this.subscription.unsubscribe();
  }


}
