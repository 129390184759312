<div id="card"  [style.background]="setColor()">
  <div id="right">
    <mat-icon class="setToWhite" (click)="RemoveCardInput()">remove</mat-icon>
    <h1 class="font-inter color-grey"><input type="text" [placeholder]="cardName" [(ngModel)]="cardNameInput" (keyup)="SaveCardName()"  (focusout)="Reload()" [readonly]="locked" class="color-white font-inter size-xl"/></h1>
    <p class="font-inter color-grey"><textarea [maxLength]="maxCharacterInput" rows="10" type="text" [placeholder]="cardText" [(ngModel)]="cardTextInput"  (focusout)="Reload()" (keyup)="SaveCardText()" [readonly]="locked"  class="color-white font-inter"></textarea></p>
  </div>
  <div id="left">
    <div id="inner">
     <div>
       <p id="infos" class="font-inter color-white ">Day/Month/Year</p>
       <p class="color-white font-inter size-xl day"><input type="number" [placeholder]="cardDay" [(ngModel)]="cardDayInput"  (focusout)="Reload()" (keyup)="setDuration();SaveCardDay()"  [readonly]="locked" class="font-inter color-white size-xl input-left"/>/<input type="number" [placeholder]="cardMonth" [(ngModel)]="cardMonthInput" [readonly]="locked" (focusout)="Reload()" (keyup)="setDuration();SaveCardMonth()" class="color-white font-inter size-xl p-5 center"/>/<input type="number" [readonly]="locked" [placeholder]="cardYear" [(ngModel)]="cardYearInput" (focusout)="Reload()"  (keyup)="setDuration();SaveCardYear()" class="color-white font-inter size-xl p-5"/></p>
     </div>
      <p class="color-white font-inter size-xl">{{cardLength}} D</p>
    </div>
    <div id="locking-length">
      <p><span class="color-white font-inter font-weigh-bold">{{textLength}}</span><span class="font-inter color-white">/150</span></p>

      <div>
      <div *ngIf="!locked">
        <mat-icon class="setToWhite" (click)="SaveLock()">lock_open</mat-icon>
      </div>
        <div *ngIf="locked">
          <mat-icon class="setToWhite" (click)="SaveLock()">lock</mat-icon>
        </div>
      </div >
    </div>
</div>

