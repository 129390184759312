import {Component, OnInit} from '@angular/core';
import {StateServiceService} from './state/service/state-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'kabanStud';


  constructor(private StateService: StateServiceService) {}

  ngOnInit(): void {
    this.StateService.connectToDatabase();
    this.StateService.CreateBasicPanel().then(response => console.log(response));

  }



}
