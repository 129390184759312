import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './page/home/home.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CardComponent } from './component/card/card.component';
import {FormsModule} from '@angular/forms';
import { AddComponent } from './component/add/add.component';
import { DataButtonComponent } from './component/data-button/data-button.component';
import { OptionsComponent } from './component/options/options.component';
import {HeaderComponent} from './component/header/header.component';
import {MatButtonModule} from '@angular/material/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {StoreModule} from '@ngrx/store';
import { PanelComponent } from './component/panel/panel.component';
import { OptionsButtonComponent } from './component/options-button/options-button.component';
import { HeaderColumnComponent } from './component/header-column/header-column.component';

@NgModule({
  declarations: [
    HomeComponent,
    CardComponent,
    AddComponent,
    DataButtonComponent,
    OptionsComponent,
    HeaderComponent,
    PanelComponent,
    OptionsButtonComponent,
    HeaderColumnComponent,
  ],
  exports: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
  ]
})
export class KabanModule { }
