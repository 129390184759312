export interface CardInterface {
  id: number;
  column: number;
  title: string;
  text: string;
  locked: boolean;
  panel: number;
  date: {
    day: number,
    month: number,
    year: number,
  };
}

export interface CardState {
  cards: CardInterface[];
}


export class Card {
  constructor(id: number,
              column: number,
              title: string,
              text: string,
              day: number,
              month: number,
              year: number,
              locked: boolean,
              panel: number){}

}

export interface PanelInterface {
  id: number;
  panel: number;
  name: string;
  actual: boolean;

}
