import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CardInterface} from '../../../state/entity/entity.card';
import {StateServiceService} from '../../../state/service/state-service.service';

const LOCAL_STORAGE_CARD_NUMBER = 'cardNumber';


@Component({
  selector: 'app-data-button',
  templateUrl: './data-button.component.html',
  styleUrls: ['./data-button.component.scss']
})
export class DataButtonComponent  implements OnInit {



  responseDataGetAll: CardInterface[] = [];

  constructor(
    private StateService: StateServiceService
  ) {}
  /*
      clear the all the data in the machine
   */
  async clear(): Promise<void> {
    await this.StateService.clearRows().then(response => response);
    await localStorage.removeItem(LOCAL_STORAGE_CARD_NUMBER);
    await this.StateService.GetAllCard().then((response: CardInterface[]) => this.setGetAllResponse(response));
    if (this.responseDataGetAll.length > 0){
      await this.clear();
    }else{
      window.location.reload();
    }
  }

  setGetAllResponse(card: CardInterface[]): void{
    card.map((item) => {
      this.responseDataGetAll.push(item);
    });
  }


  ngOnInit(): void {
  }

}
