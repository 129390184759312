import {Component, OnDestroy, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {CardInterface} from '../../../state/entity/entity.card';

import {StateServiceService} from '../../../state/service/state-service.service';
import {SetActualObservableService} from '../../../state/service/set-actual-observable.service';
import {Subscription} from 'rxjs';

const LOCAL_STORAGE_CARD_NUMBER = 'cardNumber';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  columnCentral: CardInterface[] = [];

  columnOneData: CardInterface[] = [];

  columnTwoData: CardInterface[] = [];

  columnTreeData: CardInterface[] = [];

  actual = 0;

  subscription = new Subscription();



  constructor(private StateService: StateServiceService,
              private actualObservable: SetActualObservableService) {
  }


  /**
   *  when reload : get all the card and show it
   */
  ngOnInit(): void {
    this.subscription = this.actualObservable.currentActual.subscribe(index => {
      this.actual = index;
      this.setCurrentPanel(index).then(r => r);
    });
    this.onReload().then(response => response);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /*
   * function  for reload all the card
   */
  async  onReload(): Promise<void> {
    if (this.columnOneData.length < 1  && this.columnTwoData.length < 1 && this.columnTreeData.length < 1  ) {
      await this.StateService.GetAllCard().then(response => this.setCardArray(response));
    }
  }




  // Lib
  // -------------------------------------------------------------------------

  /*
    remove the bad (evil) item
 */
  RestructureArray(id: number): CardInterface[] {
    return this.columnCentral.filter((item) => item.id !== id);
  }


// Add a Card
// --------------------------------------------------------------------------

  /*
    add the the column central and redistribute the card to the array
 */
  async addBox(column: number): Promise<void> {

    this.CreateSimpleCard(column);
    this.DistributeCard();
  }

  /*
     get the item in the localStorage
  */
  getLocalStorageItem(): number {
    if (localStorage.getItem(LOCAL_STORAGE_CARD_NUMBER)) {
      return parseInt(localStorage.cardNumber, 10);
    } else {
      localStorage.setItem(LOCAL_STORAGE_CARD_NUMBER, '0');
      return 0;
    }
  }

  /*
   create the simple card at the startup button
*/
  CreateSimpleCard(column: number): void {

    const card: CardInterface = {
      id: this.getLocalStorageItem(),
      column,
      title: '',
      text: '',
      locked: false,
      panel: this.actual,
      date: {
        day: 0,
        month: 0,
        year: 0
      }
    };
    this.columnCentral.push(card);
  }

  /*
    redistribute the card into them array as column
 */
  DistributeCard(): void {
    this.columnCentral.map((item: CardInterface) => {
      switch (item.column) {
        case 0:
          this.columnOneData.push(item);
          this.AddCard(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        case 1:
          this.columnTwoData.push(item);
          this.AddCard(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        case 2:
          this.columnTreeData.push(item);
          this.AddCard(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        default:
          this.columnCentral = this.RestructureArray(item.id);
      }
    });
  }


  AddCard(card: CardInterface): void {
    this.incrementLocalStorageNumberOfCard();
    this.StateService.addRowCard(card);
  }

  /*
  increment the localStorage to one
*/
  incrementLocalStorageNumberOfCard(): void {
    if (localStorage.getItem(LOCAL_STORAGE_CARD_NUMBER)) {
      const CardNumber = parseInt(localStorage.cardNumber, 10) + 1;
      localStorage.setItem(LOCAL_STORAGE_CARD_NUMBER, CardNumber.toString());
    } else {
      localStorage.setItem(LOCAL_STORAGE_CARD_NUMBER, '0');
    }
  }


  // Update a card
  // -----------------------------------------------------------------------

  /**
   * update the front state with the card given
   * @param card
   */
  async reload(card: CardInterface): Promise<void> {
    this.distributeUpdate(card);
  }

  /**
   * filter the column and redistribute the system
   * @param column
   * @param card
   */
  updateColumn(column: CardInterface[], card: CardInterface): void {
    const columnNew = column.filter(item => item.id !== card.id);
    columnNew.push(card);
    this.columnCentral = columnNew;
    if ( card.column === 0) { this.columnOneData = []; }
    if ( card.column === 1) { this.columnTwoData = []; }
    if ( card.column === 2) { this.columnTreeData = []; }
    this.DistributeCardAfter();
  }

  /**
   * find the good column with the card given
   * @param card
   */
  distributeUpdate(card: CardInterface): void {
    switch (card.column){
      case 0:
        this.updateColumn(this.columnOneData, card);
        break;
      case 1:
        this.updateColumn(this.columnTwoData, card);
        break;
      case 2:
        this.updateColumn(this.columnTreeData, card);
        break;
      default:
        return;
    }
  }

  // Remove a card
  // -----------------------------------------------------------------------
  // none everything in Card Component

  /*
    Remove a card from the column
 */
  removeCard($event: CardInterface): void {
    this.StateService.RemoveCard($event.id).then(r => r);
    switch ($event.column) {
      case  0:
        this.columnOneData = this.columnOneData.filter((item) => item.id !== $event.id);
        break;
      case 1:
        this.columnTwoData = this.columnTwoData.filter((item) => item.id !== $event.id);
        break;
      case 2:
        this.columnTreeData = this.columnTreeData.filter((item) => item.id !== $event.id);
    }
  }




  // Read all Card : functions for read all the card
  // -----------------------------------------------------------------------

  /*
    set the CardArray
 */

  setCardArray(cards: CardInterface[]): void {
    this.columnCentral = cards;
    this.setLocalStorageNumberCard(this.columnCentral.length);
    this.DistributeCardAfter();
  }


  /**
   *  redistribute the card given in the columnCentral
   * @constructor
   */
  DistributeCardAfter(): void {
    this.columnCentral.map((item) => {
      switch (item.column) {
        case 0:
          this.columnOneData.push(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        case 1:
          this.columnTwoData.push(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        case 2:
          this.columnTreeData.push(item);
          this.columnCentral = this.RestructureArray(item.id);
          break;
        default:
          this.columnCentral = this.RestructureArray(item.id);
      }
    });
  }

  /*
    set localstorage to the length of the columnCentral.length
 */
  setLocalStorageNumberCard(numberCard: number): void {
    if (numberCard > 0){
      localStorage.setItem(LOCAL_STORAGE_CARD_NUMBER, numberCard.toString());
    }else{
      localStorage.setItem(LOCAL_STORAGE_CARD_NUMBER, '0');
    }
  }


  // Set the panel to current
// --------------------------------------------------------------------------

  /**
   * set to the current panel
   * @param index
   */
  async setCurrentPanel(index: number): Promise<void> {
    const allData =  await this.StateService.GetAllCard().then(r => r);
    const filterData = allData.filter((item: CardInterface) => item.panel === index);
    this.columnOneData = [];
    this.columnTwoData = [];
    this.columnTreeData = [];
    this.columnCentral = filterData;
    this.DistributeCardAfter();
  }



// switcher between the column
// -------------------------------------------------------------------------

  /*
        switch the column to the  new one
     */
  switchColumn(event: CdkDragDrop<CardInterface[]>): void {
    const card = event.container.data[event.currentIndex];
    const otherCard = event.container.data.filter((item) => item.column !== card.column);
    // card.column =  otherCard.length < 1 ? card.column > 2 ? card.column = 2 :   card.column + 1 : card.column = otherCard[0].column;
    card.column = card.column > 2 ? card.column = 2 : card.column + 1;
    const query = this.StateService.QueryCard(
      card.id,
      card.column,
      card.title,
      card.text,
      card.date.day,
      card.date.month,
      card.date.year,
      card.locked,
      card.panel);
    this.StateService.UpdateCard(card.id, query);
  }


  /*
    Mat DragAndDrop
   */
  async drop(event: CdkDragDrop<CardInterface[]>): Promise<void> {
    this.onReload().then(response => response);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.switchColumn(event);
  }




}
