<div class="page">
  <app-options></app-options>
  <app-header></app-header>
  <div id="main">
    <div class="magnet"
         cdkDropList
         [cdkDropListData]="columnOneData"
         [cdkDropListConnectedTo]="[columnTwo]"
         (cdkDropListDropped)="drop($event)"
    >
      <app-add [column]="'columnOne'" (click)="addBox(0)"></app-add>
      <div class="columnHeight">
      <div *ngFor="let item of columnOneData; let i = index" cdkDrag>
        <app-card [column]="'columnOne'" [index]="i" [card]="item" (removeItem)="removeCard($event)" (reload)="reload($event)"></app-card>
      </div>
      </div>
    </div>
    <div class="magnet"
         cdkDropList
         #columnTwo="cdkDropList"
         [cdkDropListData]="columnTwoData"
         [cdkDropListConnectedTo]="[columnTree]"
         (cdkDropListDropped)="drop($event)">
      <app-add [column]="'columnTwo'" (click)="addBox(1)"></app-add>
      <div class="columnHeight">
      <div *ngFor="let item of columnTwoData ; let i = index" cdkDrag>
        <app-card [column]="'columnTwo'" [card]="columnTwoData[i]" (removeItem)="removeCard($event)" (reload)="reload($event)"></app-card>
      </div>
      </div>



    </div>
    <div class="magnet"
         cdkDropList
         #columnTree="cdkDropList"
         [cdkDropListData]="columnTreeData"
         [cdkDropListConnectedTo]="[columnTwo]"
         (cdkDropListDropped)="drop($event)">
      <app-add [column]="'columnTree'" (click)="addBox(2)"></app-add>
      <div class="columnHeight">
      <div *ngFor="let item of columnTreeData; let i = index" cdkDrag>
        <app-card [column]="'columnTree'" [card]="columnTreeData[i]" (removeItem)="removeCard($event)" (reload)="reload($event)"></app-card>
      </div>
      </div>
    </div>

  </div>
</div>
