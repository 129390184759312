import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CardInterface} from '../../../state/entity/entity.card';
import {StateServiceService} from '../../../state/service/state-service.service';


interface Emitter  {
  index?: number;
  date?: Date;
}


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent  implements OnInit {

  @Input()
  column: string;

  @Input()
  card: CardInterface;

  @Input()
  index: number;

  @Output()
  removeItem: EventEmitter<CardInterface> = new EventEmitter<CardInterface>();

  @Output()
  reload: EventEmitter<CardInterface> = new EventEmitter<CardInterface>();

  cardName: string;

  cardText: string;

  cardMonth: number;

  cardDay: number;

  cardYear: number;

  cardLength: number;

  textLength: number;


  cardNameInput: string;

  cardTextInput: string;

  cardDayInput: number;

  maxCharacterInput: number;

  cardMonthInput: number;

  cardYearInput: number;

  locked: boolean;

  lastDigitYear: number;

  monthUnderTen = false;


  constructor(
    private StateService: StateServiceService,
  ) {
    this.card = {
      id : 0,
      column: 0,
      title : '',
      text : '',
      panel: 0,
      locked: false,
      date : {
        day : 0,
        month : 0,
        year : 0
      }
    };
    this.cardName = '';
    this.cardText = '';
    this.cardMonth = 0;
    this.cardDay = 0;
    this.cardLength = 0;
    this.textLength = 0;
    this.cardNameInput = 'hello';
    this.cardTextInput = 'Un jolie bateau';
    this.cardDayInput = 0;
    this.cardMonthInput = 0;
    this.maxCharacterInput = 150;
    this.lastDigitYear = parseInt(new Date().getUTCFullYear().toString().substr(-2), 10);
    this.cardYear = 0;
    this.cardYearInput = 0;
    this.column = '';
    this.index = 0;
    this.locked = false;
  }


  SaveCardText(): void {
    const data = this.card;
    if (data.locked){
    } else if (this.cardText.length > 1 ){
      this.cardText = this.cardTextInput;
      const query = this.StateService.QueryCard(
        data.id,
        data.column,
        data.title,
        this.cardTextInput,
        data.date.day,
        data.date.month,
        data.date.year ,
        data.locked,
        data.panel);
      this.StateService.UpdateCard(this.index, query);
    } else if (this.cardText.length < 1){
      this.cardText = 'You need to enter something';
    }
  }



  SaveCardName(): void {
    if (this.cardName.length > 1){
      const data = this.card;
      this.cardName = this.cardNameInput;
      const query = this.StateService.QueryCard(
        data.id,
        data.column,
        this.cardNameInput,
        data.text,
        data.date.day,
        data.date.month,
        data.date.year,
        data.locked,
        data.panel);
      /* this.UpdateTitle.emit(query);*/
      this.StateService.UpdateCard(this.index, query);
    }
    this.cardName = 'Enter something !';
  }

  SaveCardDay(): void {
    if (this.cardDay === 0){
      const data = this.card;
      this.cardDay = this.cardDayInput;
      const query = this.StateService.QueryCard(data.id,
        data.column,
        data.text,
        data.text,
        this.cardDayInput,
        data.date.month,
        data.date.year,
        data.locked,
        data.panel);
      this.StateService.UpdateCard(this.index, query);
    }else{
      this.cardDay = 0;
    }
  }


  SaveCardMonth(): void {
    const data = this.card;
    if (this.cardMonth  === 0){
      this.cardMonth = this.cardMonthInput;
      const query: CardInterface = {
        id: data.id,
        column: data.column,
        text: data.text,
        locked: data.locked,
        panel: data.panel,
        title: data.title,
        date: {
          day: data.date.day,
          month: this.cardMonthInput,
          year: data.date.year
        }
      };
      this.StateService.UpdateCard(this.index, query);
    }else{
      this.cardMonth = 0;
    }
  }

  SaveCardYear(): void{
    const data = this.card;
    if (this.cardYear === 0 && data.date.year > 0){
      this.cardYear =  this.cardYearInput;
      const query = this.StateService.QueryCard(
        data.id,
        data.column,
        data.text,
        data.title,
        data.date.day,
        data.date.month,
        this.cardYearInput,
        data.locked,
        data.panel);
      this.StateService.UpdateCard(this.index, query);
    }else{
      this.cardYear = this.lastDigitYear;
    }
  }

  SaveLock(): void {
    const data = this.card;
    this.locked = !this.locked;
    const query = this.StateService.QueryCard(
      data.id,
      data.column,
      data.text,
      data.title,
      data.date.day,
      data.date.month,
      data.date.year,
      this.locked,
      data.panel,
    );
    this.StateService.UpdateCard(this.index, query);
    this.Reload();
  }

  ShowTextInput(): void {
    this.textLength = this.cardTextInput.length;
  }

  RemoveCardInput(): void {
    this.removeItem.emit(this.card);
  }

  Reload(): void {
    const card: CardInterface = {
      id : this.card.id,
      title: this.cardNameInput,
      text: this.cardTextInput,
      column: this.card.column,
      locked: this.locked,
      panel: this.card.panel,
      date: {
        day: this.cardDayInput,
        month: this.cardMonthInput,
        year: this.cardYearInput,
      }
    };
    this.reload.emit(card);
  }


  /*
      set the number of the day before the date
   */
  setDuration(): void {
    const oneDay = 1000 * 60 * 60 * 24;
    const actualDate = new Date();
    const inputDateInput = new Date(Date.UTC(this.cardYearInput + 2000, this.cardMonthInput - 1, this.cardDayInput));
    const result = new Date(inputDateInput.toISOString().split('T')[0]);

    // verify if the input is inferior of the actual time
    if (result.getTime() < actualDate.getTime()){
      this.cardLength = 0;
      return;
    }

    // verify if the year of the input time is superior of the year of the actual
    // if yes return the difference between the (input.year + 1) - input
    if (result.getTime() >= actualDate.getTime()){
      this.cardLength = Math.ceil(Math.abs(result.getTime() - actualDate.getTime()) / oneDay);
      return;
    }

  }

  setToYear(): void {
    if (this.cardYear === 0){
      this.cardYearInput = this.lastDigitYear;
    }
  }

  setColor(): string {

    if (this.column === 'columnOne') {
      return 'orange';
    }

    if (this.column === 'columnTwo'){
      return 'red';
    }

    if (this.column === 'columnTree'){
      return  'green';
    }
    return '';

  }

  /*
      set this.x to the card interface;
   */
  updater(card: CardInterface): void {
    if (card.title.length > 0){
      this.cardNameInput = card.title;
    }

    if (card.text.length > 0){
      this.cardTextInput = card.text;
      this.ShowTextInput();
    }

    if (card.date.day > 0){
      this.cardDayInput = card.date.day;
    }

    if (card.date.month > 0){
      this.cardMonthInput = card.date.month < 10 ?  parseInt(card.date.month.toString().padStart(1, '0'), 10) : card.date.month;
    }

    if (card.date.year > 0){
      this.cardYearInput = card.date.year;
    }
    this.index = card.id;
    this.locked = card.locked;

  }

  ngOnInit(): void {
    this.setToYear();
    this.setColor();
    this.updater(this.card);
    this.setDuration();
  }


}
